import React, { useEffect, useState } from 'react';
import I18n from 'i18n-js';
import axios from 'axios';
import MetricModal from './MetricModal';

const DataReportInfoModal = ({ device_id, dataReportId, metrics, reportType, locale }) => {
  let [report, setReport] = useState([]);
  let [time, setTime] = useState('');
  let [selectedMetric, setSelectedMetric] = useState('');

  useEffect(() => {
    const options = {
      data_report_id: dataReportId,
    };
    axios.get(Routes.data_logs_info_api_v1_device_path(device_id, options)).then((response) => {
      if (response.data !== null) {
        setReport(response.data.report);
        setTime(response.data.time);
      }
    });
  }, [dataReportId]);

  function showButton(metric, value) {
    if (value === 'show_modal_sensor' || value === 'show_modal_input') {
      return (
        <button
          className="btn btn-small"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#showMetricModal"
          onClick={() => {
            setSelectedMetric(metric);
          }}
        >
          <i className={'fas ' + (value === 'show_modal_sensor' ? 'fa-chart-bar' : 'fa-list')}></i>
        </button>
      );
    } else if (metric === 'status' && value) {
      const parsedData = JSON.parse(value);
      return (
        <span>
          <i className="fas fa-arrow-circle-down securus-blue"></i> {parsedData.rx}{' '}
          <i className="fas fa-arrow-circle-up securus-blue"></i> {parsedData.tx}
        </span>
      );
    } else {
      return value;
    }
  }

  function getIcon(type) {
    switch (type) {
      case 'relays':
      case 'input':
        return <i className="fas fa-toggle-on securus-blue fs-6"></i>;
      case 'sensor':
        return <i className="fas fa-thermometer-half text-danger fs-5"></i>;
      case 'volt':
        return <i className="fas fa-bolt text-warning fs-5"></i>;
      case 'status':
        return <i className="fas fa-signal securus-blue fs-5"></i>;
      default:
        return <i className="fas fa-paw fs-5"></i>;
    }
  }
  return (
    <span>
      <MetricModal
        device_id={device_id}
        dataReportId={dataReportId}
        selectedMetric={selectedMetric}
        reportType={reportType}
        locale={locale}
      />
      <div className="modal fade display-none" id="showDataReportInfoModal" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg" style={{ width: '600px' }}>
          <div className="modal-content rounded-1 shadow">
            <div className="modal-header">
              <h1 className="modal-title fs-5">{time}</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {report ? (
                <table className="table mt-3">
                  <tbody>
                    {metrics.map((metric, index) => {
                      return (
                        <tr className="border-top" key={`metrics_tr_${index}`}>
                          <td className="p-2">{getIcon(metric.type)}</td>
                          <td className="text-start p-2">
                            {metric.name === 'status' ? I18n.t('devices.logs.report_modal.data_usage') :
                              metric.name.length > 50 ? `${metric.name.substring(0, 47)}...` : metric.name
                            }
                          </td>
                          <td className="p-2">{showButton(metric.accessor, report[index])}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <span className="loading-text"></span>
              )}
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default DataReportInfoModal;
